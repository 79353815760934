<template>
  <b-modal
    hide-footer
    ref="deletePermission"
    id="configurator"
    size="lg"
    no-close-on-backdrop
    :title="content && content.id + `.` + content.name"
    centered
  >
    <div class="grid-container">
      <div class="none"></div>
      <div class="VIEW font-weight-bold font-size-h3">Podgląd</div>
      <div class="EDIT font-weight-bold font-size-h3">Edycja</div>
      <div class="CREATE font-weight-bold font-size-h3">Tworzenie</div>
      <div class="DELETE font-weight-bold font-size-h3">Usuwanie</div>
      <div class="infinity">Nieskończoność</div>
      <div class="range">Zakres</div>
      <div class="days">Dni</div>

      <div
        v-for="perm in permissionLabel"
        :class="'infinity' + perm[0].toUpperCase() + perm.slice(1)"
        class="col p-3"
        :key="perm + 'INFINITE'"
      >
        <b-form-checkbox
          @change="(e) => checkSwitch(e, 'infinity', perm)"
          v-model="infinity[perm]"
          switch
        >
        </b-form-checkbox>
        <i class="fa fa-infinity" v-if="infinity[perm]"></i>
      </div>

      <!--    RANGE INPUTS -->
      <div
        v-for="perm in permissionLabel"
        :class="'range' + perm[0].toUpperCase() + perm.slice(1)"
        class="col p-3"
        :key="perm + 'RANGE'"
      >
        <b-form-checkbox
          @change="(event) => checkSwitch(event, 'range', perm)"
          v-model="range[perm]"
          switch
        >
        </b-form-checkbox>
        <div class="input-group col p-3" v-if="range[perm]">
          <date-picker
            class="rounded w-100"
            v-model="form[perm]"
            type="datetime"
            value-type="format"
            format="YYYY-MM-DD"
            placeholder="Wybierz zakres"
            range
            :show-time-panel="false"
          />
        </div>
      </div>

      <!-- DAYS -->
      <div
        v-for="perm in permissionLabel"
        :class="'days' + perm[0].toUpperCase() + perm.slice(1)"
        class="col p-3"
        :key="perm"
      >
        <b-form-checkbox @change="(e) => checkSwitch(e, 'days', perm)" v-model="days[perm]" switch>
        </b-form-checkbox>
        <b-input-group v-if="days[perm]" size="sm" append="dni">
          <b-form-input v-model="form[perm]" number></b-form-input>
        </b-input-group>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary text-white"
        data-dismiss="modal"
        @click="closeModal"
      >
        {{ $t("CLOSE") }}
      </button>
      <button
        type="button"
        @click="sendForm"
        class="btn btn-primary text-white"
        :class="{ disabled: isObjectEmpty(this.form) }"
      >
        {{ $t("SAVE") }}
      </button>
    </div>
  </b-modal>
</template>

<script>
import DatePicker from "vue2-datepicker";
import { ROLE_CONFIGURATOR } from "@/core/store/Roles/role.module";

export default {
  name: "SwitchForm",
  components: { DatePicker },
  props: {
    nameDispatch: String,
    openModal: Boolean,
    url: {
      type: String,
      require: true,
      default: "/api/role-permissions",
    },
    name: {
      type: String,
      default: "entity",
    },
    content: {
      default() {
        return { id: 1, title: "User" };
      },
    },
  },
  data() {
    return {
      permissionLabel: ["view", "edit", "create", "delete"],

      form: {
        view: null,
        edit: null,
        create: null,
        delete: null,
      },
      infinity: {
        view: null,
        edit: null,
        create: null,
        delete: null,
      },
      range: {
        view: null,
        edit: null,
        create: null,
        delete: null,
      },
      days: {
        view: false,
        edit: false,
        create: false,
        delete: false,
      },
    };
  },
  methods: {
    closeModal() {
      this.form = {};
      this.range = {};
      this.infinity = {};
      this.days = {};
      this.$bvModal.hide("configurator");
    },
    sendForm() {
      for (let action in this.form) {
        let val = this.form[action] !== null && this.form[action];
        let timeRule = {};
        if (!val) {
          this.$emit("render");
          return;
        }
        switch (typeof val) {
          case "boolean": // INFINITY
            timeRule.type = "infinite";
            break;
          case "number": // DAYS
            timeRule = {
              type: "instant",
              expires_after: val,
            };
            break;
          case "object": // RANGE
            timeRule = {
              begins_on: `${val[0]} 00:00`,
              expires_on: `${val[1]} 00:00`,
              type: "range",
            };
            break;
          default:
            break;
        }
        const context = {
          roleUserId: Number(this.$route.params.id),
          entityName: this.content.entityName,
          entityId: this.content.entityId ? this.content.entityId : null,
          fields: null,
          action: action.toUpperCase(),
          attachmentEntityName: null,
          attachmentEntityId: null,
          userId: Number(this.$route.params.id),
          duration: { timeRule },
        };
        this.$store
          .dispatch(this.nameDispatch || ROLE_CONFIGURATOR, { ...context, timeRule })
          .then(() => {
            this.toastSuccess("Success add permission", "Success");
            this.$emit("render");
            this.closeModal();
          });
      }
    },
    checkSwitch(value, durationName, permissionName) {
      let duration = ["infinity", "range", "days"].filter((i) => i !== durationName);
      let permissions = ["view", "edit", "create", "delete"];
      const otherDurationObjectEmpty =
        this.isObjectEmpty(this[duration[0]]) && this.isObjectEmpty(this[duration[1]]);
      if (otherDurationObjectEmpty && permissionName !== "view") {
        let arrayDuration = Object.entries(this[durationName]);
        let switched = true;
        // eslint-disable-next-line array-callback-return
        arrayDuration.map((item, index) => {
          if (arrayDuration[index === 0 ? index : index - 1][0] === permissionName) {
            switched = false;
          }
          arrayDuration[index][1] = switched;
          this.form[arrayDuration[index][0]] = switched;
        });
        this[durationName] = Object.fromEntries(arrayDuration);
      }
      if (
        !this.infinity[permissionName] &&
        !this.range[permissionName] &&
        !this.days[permissionName]
      ) {
        for (let i of permissions.slice(permissions.indexOf(permissionName) + 1)) {
          this.infinity[i] = null;
          this.range[i] = null;
          this.days[i] = null;
        }
      }
      for (let el of duration) {
        this[el][permissionName] = null;
      }
      this.form[permissionName] = durationName === "infinity" || 0;
    },
  },
};
</script>

<style lang="scss">
.grid-container {
  min-height: 300px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-template-areas:
    "none VIEW EDIT CREATE DELETE"
    "infinity infinityView infinityEdit infinityCreate infinityDelete"
    "range rangeView rangeEdit rangeCreate rangeDelete"
    "days daysView daysEdit daysCreate daysDelete";

  .col {
    //padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(17, 17, 17, 0.05);
  }
}

.none {
  padding: 10px;
  grid-area: none;
}

.VIEW {
  padding: 10px;
  grid-area: VIEW;
}

.EDIT {
  padding: 10px;
  grid-area: EDIT;
}

.CREATE {
  padding: 10px;
  grid-area: CREATE;
}

.DELETE {
  padding: 10px;
  grid-area: DELETE;
}

.infinity {
  padding: 2px;
  grid-area: infinity;
}

.range {
  padding: 2px;
  grid-area: range;
}

.days {
  padding: 2px;
  grid-area: days;
}

.infinityView {
  padding: 0 2px;
  grid-area: infinityView;
}

.infinityEdit {
  padding: 0 2px;
  grid-area: infinityEdit;
}

.infinityCreate {
  padding: 0 2px;
  grid-area: infinityCreate;
}

.infinityDelete {
  padding: 0 2px;
  grid-area: infinityDelete;
}

.rangeView {
  padding: 0 2px;
  grid-area: rangeView;

  .col-4 {
    padding: 0 !important;
  }

  .col {
    margin: 0 !important;
    padding: 0 !important;
  }
}

.rangeEdit {
  padding: 0 2px;
  grid-area: rangeEdit;

  .col-4 {
    padding: 0 !important;
  }

  .col {
    margin: 0 !important;
    padding: 0 !important;

    * {
      border: none !important;
    }
  }
}

.rangeCreate {
  padding: 0 2px;
  grid-area: rangeCreate;

  .col-4 {
    padding: 0 !important;
  }

  .col {
    margin: 0 !important;
    padding: 0 !important;

    * {
      border: none !important;
    }
  }
}

.rangeDelete {
  padding: 0 2px;
  grid-area: rangeDelete;

  .col-4 {
    padding: 0 !important;
  }

  .col {
    margin: 0 !important;
    padding: 0 !important;

    * {
      border: none !important;
    }
  }
}

.daysView {
  padding: 0 2px;
  grid-area: daysView;
}

.daysEdit {
  padding: 0 2px;
  grid-area: daysEdit;
}

.daysCreate {
  padding: 0 2px;
  grid-area: daysCreate;
}

.daysDelete {
  padding: 0 2px;
  grid-area: daysDelete;
}
</style>
